var exports = {};

// ref: https://github.com/tc39/proposal-global
var getGlobal = function () {
  // the only reliable means to get the global object is
  // `Function('return this')()`
  // However, this causes CSP violations in Chrome apps.
  if (typeof self !== "undefined") {
    return self;
  }

  if (typeof window !== "undefined") {
    return window;
  }

  if (typeof _global2 !== "undefined") {
    return _global2;
  }

  throw new Error("unable to locate global object");
};

var _global2 = getGlobal();

exports = exports = _global2.fetch; // Needed for TypeScript and Webpack.

if (_global2.fetch) {
  exports.default = _global2.fetch.bind(_global2);
}

exports.Headers = _global2.Headers;
exports.Request = _global2.Request;
exports.Response = _global2.Response;
export default exports;
export const Headers = exports.Headers,
      Request = exports.Request,
      Response = exports.Response;